import { DetailsForm } from "../../Components";
import { BusinessData } from "../../constant";

export const WhychooseUs = () => {
  return (
    <div className=" bg-whychooseusBg p-4 xxs:h-full">
      <div className="xxs:w-11/12 xl:w-3/4 lg:my-32 xxs:my-12 xxs:mx-auto flex lg:gap-8  xl:flex-row xxs:flex-col xxs:h-full">
        <div className="lg:w-[85%] xl:w-1/2  m-auto">
          <div className="text-left">
            <span className="text-primary lg:text-lg lg:leading-[22px] font-montserrat">
              {/* WHY CHOOSE US */}
              Why Tamkin  
            </span>
            <p className="xl:text-[39px] xl:leading-[56px] lg:text-[40px] lg:leading-[52px] md:text-[32px] md:leading-[45px] xxs:text-[19px] xxs:leading-[40px] font-montserrat font-bold">
              We Provide Best Solution For Your Business
            </p>
          </div>

          <div className="pt-8">
            {BusinessData.map((item, index) => {
              return (
                <>
                  <div className="flex gap-4 xl:pt-10 xxs:pt-2" key={index}>
                    {/* <div className="md:pt-0 xs:pt-[5px] xxs:pt-[8px]"> */}
                      <img src={item.icon} alt="icon" className="w-11 h-full" />
                    {/* </div> */}
                    <div className="text-left flex flex-col gap-4">
                      <span className="text-lg flex flex-col font-bold font-montserrat">
                        {item.title}
                      </span>
                      <p className="text-base leading-[26px] font-montserrat">
                        {item.para}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {/* <div className="2xl:w-[40%] xl:w-[45%] lg:w-[60%] md:w-[70%] md:m-auto h-auto xl:m-0 lg:m-auto flex  justify-center bg-detailBackground bg-no-repeat 2xl:bg-contain xl:bg-cover lg:bg-contain xxs:bg-cover">
          <DetailsForm />
        </div> */}

        <div className="xxs:w-full 2xl:w-1/2 xl:w-[45%] lg:w-[60%] md:w-[70%] md:m-auto xl:h-auto lg:h-[700px] xxs:h-[600px] xl:m-0 lg:m-auto flex  justify-center relative">
          <div className="dark_blue w-[90%] h-full bg-trialgradient"></div>
          <div className="light_blue w-full h-full  bg-primary absolute ">
            <DetailsForm />
          </div>
        </div>
      </div>
    </div>
  );
};
