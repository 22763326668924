import { useFormik } from "formik";


export const DetailsForm = () => {
  const submitHandler = (e) => {};
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.length > 20) {
      errors.name = "Must be 15 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.phone_number) {
      errors.phone_number = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      selectproject: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      console.log(values, "values");
      submitHandler();
      formik.handleReset();
    },
  });

  return (
    <>
      <div className="dropdown-form w-[75%] m-auto 2xl:pt-24 lg:pt-16 md:pt-24  xxs:py-16 xxs:px-0 ">
        <h1 className="2xl:text-[40px] 2xl:leading-[49px] xl:text-[36px] xs:text-[23px] xxs:text-[19px] font-montserrat font-bold text-white py-4 xxs:pt-24 lg:pt-36 md:ml-0 xxs:text-left">Request A Quote</h1>
        <form
          onSubmit={formik.handleSubmit}
          // className="flex flex-col gap-4 xl:w-3/5 md:w-10/12	xxs:w-full xxs:p-0  m-auto "
          className="flex flex-col lg:gap-8 xxs:gap-4 w-full  m-auto "
        >
          <input
            type="text"
            placeholder="Your Name"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            className={
              "focus-visible:outline-none focus:outline-none focus-within:none  border-none lg:text-base xxs:text-sm  font-montserrat lg:leading-5 px-3 placeholder-footerbgColor py-4 w-full " +
              `${
                formik.touched.name && formik.errors.name
                  ? "career-input-error"
                  : " "
              }`
            }
          />
          <input
            type="email"
            placeholder="Your Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            className={
              "focus-visible:outline-none focus:outline-none focus-within:outline-none lg:text-base xxs:text-sm font-montserrat lg:leading-5 px-3 placeholder-footerbgColor py-4 w-full " +
              `${
                formik.touched.email && formik.errors.email
                  ? "career-input-error"
                  : " "
              }`
            }
          />
          <input
            type="number"
            placeholder="Your Phone"
            onChange={formik.handleChange}
            value={formik.values.phone_number}
            name="phone_number"
            className={
              "focus-visible:outline-none focus:outline-none  focus-within:outline-none  lg:text-base xxs:text-sm lg:leading-5 font-montserrat px-3 placeholder-footerbgColor py-4 w-full " +
              `${
                formik.touched.phone_number && formik.errors.phone_number
                  ? "career-input-error"
                  : " "
              }`
            }
          />

          <input
            type="text"
            placeholder="Your Subject"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            className={
              "focus-visible:outline-none focus:outline-none focus-within:outline-none  border-none  lg:text-base xxs:text-sm font-montserrat lg:leading-5 px-3 placeholder-footerbgColor py-4 w-full " +
              `${
                formik.touched.name && formik.errors.name
                  ? "career-input-error"
                  : " "
              }`
            }
          />
          <div className="border boredr-solid border-white  m-auto text-white flex justify-center rounded-sm py-5 px-3 w-full cursor-pointer">
            <button
              type="submit"
              className="font-montserrat leading-4 text-xs "
            >
              Submit Now{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
