import { Experience } from "../../assets";

export const WeOffer = () => {
  return (
    <>
      <div className="xxs:w-11/12 xl:w-[75%] lg:my-20 xxs:my-8 xxs:mx-auto flex lg:gap-8  xl:flex-row xxs:flex-col">
        <div className="lg:w-[85%] xl:w-[40%] 2xl:h-[600px] xl:h-[530px] md:w-full m-auto">
          <img src={Experience} alt="happyMan" className="w-full h-full" />
        </div>
        <div className="xl:w-[50%] h-auto flex flex-col gap-4 lg:pt-0  md:pt-12 xxs:pt-4 lg:w-[85%] m-auto 2xl:h-[600px] xl:h-[530px]">
          <h1 className="text-primary font-montserrat lg:text-lg lg:leading-[22px] xxs:text-base  md:pt-0  xxs:pt-4 text-left">
            ABOUT US{" "}
          </h1>
          <p className="text-start 2xl:w-[85%] 2xl:text-[40px] 2xl:leading-[53px] lg:text-[35px] lg:leading-[45px] md:text-[35px] md:leading-[45px] xs:text-[20px] xs:leading-[35px] xxs:text-[20px] font-bold font-montserrat">
            We Offer Professional Solutions For Your Business
          </p>
          <p className="text-start lg:text-base lg:leading-[28px] xxs:text-sm xxs:leading-[30px] font-montserrat">
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also Aldus PageMaker including versions of
            Lorem Ipsum. Contrary to popular classical Latin literature from 45
            BC, making it over 2000 years old. */}
            Tamkin was founded in 2020 by Mohammed Alyahyaei former General
            Director of semi-government entity with over 25 years of experience
            in private and government sector. Today, we are a professional team
            of change fanatics and advocates focused on customer success. We're
            passionate about helping your organization build effective change
            capabilities. We combine scientific principles and a focus on the
            people and technical side of change to deliver superior training
            programs and consultancy through partnering with the world's largest
            body of change management and project management : Project
            Management Institute (PMI) and Prosci institute of change
            management.
          </p>

          {/* <div className="flex gap-8 md:flex-row  xxs:flex-col xxs:items-center">
            {ProjectData.map((item, index) => {
              return (
                <div
                  className="border border-solid border-bordercolor rounded-2xl md:p-6 md:w-48  xxs:w-11/12 xxs:p-12  flex flex-col items-center justify-center"
                  key={index}
                >
                  <div className="flex items-center justify-center lg:text-[49px] lg:leading-[65px]  xxs:text-[50px] text-center">
                   
                    <CountUp
                      start={0}
                      end={item.count}
                      enableScrollSpy={true}
                      className="font-montserrat font-bold text-numcolor text-center"
                    />
                    <span className="text-numcolor lg:text-[49px] lg:leading-[65px] xxs:text-[50px] font-montserrat font-bold text-center">
                      +
                    </span>
                  </div>
                  <p className="md:text-lg md:leading-[24px] xxs:text-[23px]  font-montserrat font-semibold text-center whitespace-nowrap">
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div> */}
          {/* <div className="md:pt-0 xxs:pt-4">
            <button className="text-white bg-primary rounded md:m-0  xxs:m-auto whitespace-nowrap text-center text-xs font-montserrat leading-[15px] flex items-center lg:px-[59px] lg:py-[21px] xxs:py-[15px] px-[40px]">
              {" "}
              View More About Us
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};
