import { aboutbannerBackground } from "../../assets";
import { Banner, Footer } from "../../Components";
import { ProjectDetails } from "../../Container";

export const ProjectManagement = () => {
  return (
    <>
      <Banner
        backgroundImage={aboutbannerBackground}
        title="Project Management"
        // id="#embroidery"
      />
      <ProjectDetails />
      <Footer />
    </>
  );
};
