import { ChangeManagementContent, needManagement } from "../../constant";

export const WhyChangeManagement = () => {
  return (
    <>
      <div className="xxs:w-11/12 xl:w-3/4 mx-auto">
        <div className="2xl:pt-16 xxs:pt-6">
          <h1 className="font-bold 2xl:text-[40px] 2xl:leading-[53px] lg:text-[30px] md:text-[22px] xxs:text-lg font-montserrat py-8 ">
            Why Change Management 
          </h1>
        </div>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor">
          Chang is taking place in your organization every day with new projects
          and initiatives to improve performance, increase profits, and enhance
          your competitive advantage. You could be implementing technology to
          enable a more mobile workforce, re-engineering a process to ensure
          regulatory compliance, or pursuing an enterprise-wide transformation
          around customer experience. In all of these changes, people are the
          common denominator for achieving intended outcomeswhich is why you
          need change management. 
        </p>
        <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[21px] xs:leading-[38px] xxs:leading-[38px] xxs:text-lg  text-start pt-8">
          Why Do You Need Change Management?
        </h3>
        <p className="text-bordercolor md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start">
          Change management is the discipline that guides how we prepare, equip
          and support individuals to successfully adopt change in order to drive
          organizational success and outcomes. Helping individuals through
          their individual change journeys enables organizations to:
        </p>
        <div className="pt-4">
          {needManagement.map((item, index) => {
            return (
              <>
                <div className="text-start" key={index}>
                  <h1 className="md:text-[22px] md:leading-[56px] xs:text-[17px] xs:leading-[40px] xxs:text-base font-montserrat font-bold md:pt-4 xxs:pt-8">
                    {item.heading}
                  </h1>
                  <p className="md:text-lg md:leading-[32px] xxs:text-base  xxs:leading-[38px]  font-montserrat text-bordercolor md:pt-0  xxs:pt-4">
                    {item.para}
                  </p>

                  {item?.image !== "" && (<div className="flex justify-center py-12">
                    <img src={item.image} alt="item" />
                  </div>)}
                </div>
              </>
            );
          })}
        </div>
        <h1 className="font-bold 2xl:text-[40px] 2xl:leading-[53px] lg:text-[30px] md:text-[22px] xxs:text-lg  font-montserrat md:py-8 xxs:py-4">What is Change Management :</h1>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px]  font-montserrat  text-start text-bordercolor">
          Change management as a discipline has evolved and matured over the
          past quarter of a century. Prosci research tells us that for changes
          to be successful, we must prepare, equip and support individuals
          moving through changes so that they successfully adopt the changes.
          Without adoption, changes will not be successful and we will not
          deliver the desired outcomes.
        </p>
        <div>
          { ChangeManagementContent.map((item, index) => {
            return(
              <>
              <div className="text-start">
                <h1 className="md:text-[22px] md:leading-[56px] xxs:leading-[40px] xxs:text-base font-montserrat font-bold md:pt-4 xxs:pt-6">{item.title}</h1>
                <p className="md:text-lg md:leading-[32px] xxs:text-base xxs:leading-[38px] font-montserrat   text-bordercolor">{item.details}</p>
              </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  );
};
 