import { AboutOurClient } from "../../constant";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 2,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};

export const OurClient = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-primary border-2	 border-solid border-primary"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-dotcolor border-2 border-solid border-white"
          }
        ></div>
      </li>
    );
  };
  return (
    <>
      <div className="xxs:w-11/12 xl:w-[75%] xl:my-20 lg:my-24 xxs:my-12 xxs:mx-auto lg:gap-24 xxs:flex-col">
        <div>
          <h1 className="lg:text-[40px] lg:leading-[56px] xs:text-xl xs:leading-[40px] xxs:leading-[40px] font-bold font-montserrat">
            What Our Clients Say About Us
          </h1>
          <p className="lg:text-base lg:leading-[29px] xs:text-xs xs:leading-[25px] xxs:text-[11px] xxs:leading-[25px]">
            Our purpose is to deliver excellence in service and execution.
            {/* Our
            purpose is to deliver excellence in service
            <br /> and Our purpose is to deliver excellence in service. */}
          </p>
        </div>
        <div className="w-full m-auto">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2500}
            ssr={true}
            showDots={true}
            customDot={<CustomDot />}
            removeArrowOnDeviceType={[
              "mobile",
              "tablet",
              "desktop",
              "superLargeDesktop",
              "smmobile",
            ]}
          >
            {AboutOurClient.map((item, index) => {
              return (
                <div className="md:ml-6  h-full" key={index}>
                  <div
                    className=" card-container p-12 gap-12 flex flex-col my-20 md:h-[70%] xxs:h-auto"
                    key={index}
                  >
                    {/* <div className="flex items-end justify-between">
                      <div className="w-[20%]">
                        <img src={item.icon} alt="icon" />
                      </div>
                      <div className="lg:w-[30%] mt-[-9rem] md:w-[40%] xxs:w-[30%]">
                        <img
                          src={item.image}
                          className="w-full h-full"
                          alt="lady"
                        />
                      </div>
                    </div> */}

                    <div className="flex flex-col gap-4">
                      <p className="text-base leading-[25px] font-montserrat text-left">
                        {item.detail}
                      </p>
                      <h2 className="lg:text-[20px] lg:leading-[24px] font-montserrat font-semibold text-left xs:text-base">
                        {item.name}
                      </h2>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
