import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  BionixLogo,
  CustomerIcon1,
  CustomerIcon2,
  CustomerIcon3,
  CustomerIcon4,
  CustomerIcon5,
  CustomerIcon6,
  CustomerIcon7,
  CustomerIcon8,
  CustomerIcon9,
  DjangoLogo,
  OzenLogo,
} from "../../assets";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 4,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 2,
  },
};

const Images = [
  { image: CustomerIcon1, name: BionixLogo },
  { image: CustomerIcon2, name: DjangoLogo },
  { image: CustomerIcon3, name: BionixLogo },
  { image: CustomerIcon4, name: BionixLogo },
  { image: CustomerIcon5, name: BionixLogo },
  { image: CustomerIcon6, name: OzenLogo },
  { image: CustomerIcon7, name: CustomerIcon7 },
  { image: CustomerIcon8, name: CustomerIcon8 },
  { image: CustomerIcon9, name: CustomerIcon9 },
];

const renderImage = () => {
  return Images.map((instance, index) => {
    return (
      <div
        className="w-28 h-28  flex justify-center items-center m-auto"
        key={index}
      >
        <div className="w-36 h-auto">
          <img
            key={index}
            src={instance.image}
            alt={instance?.image}
            className="w-full h-full"
          />
        </div>
      </div>
    );
  });
};

export const AboutLanguage = () => {
  return (
    <>
      <div className="xxs:w-11/12 xl:w-3/4 mx-auto xl:py-8 ">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1500}
          removeArrowOnDeviceType={[
            "mobile",
            "tablet",
            "desktop",
            "superLargeDesktop",
            "smmobile",
          ]}
        >
          {renderImage()}
        </Carousel>
      </div>
    </>
  );
};
