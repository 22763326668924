export const HomeBanner = () => {
  return (
    <div className="flex items-center bg-bannerBackground bg-center bg-no-repeat bg-cover h-full xxs:h-auto  md:py-0">
      <div className="banner-container h-full lg:p-36 xxs:p-12">
        <div className="2xl:w-[85%] xl:w-[95%]   xxs:w-full m-auto xxs:flex items-center xxs:flex-col-reverse md:flex-row">
          <div className="md:w-[60%] flex flex-col xxs:gap-3  xxs:w-full">
            <span className="text-white font-montserrat 2xl:text-7xl md:text-left xxs:text-center 2xl:leading-[97px] xs:text-[32px] xxs:text-[20px]">
              {/* Solutions For{" "} */}
              Transform your
            </span>
            <h1 className="text-white font-bold font-montserrat md:text-left xxs:text-center 2xl:text-[71px] 2xl:leading-[97px] lg:text-[38px] xs:text-[27px] xxs:text-[25px]">
              {/* Business Growth{" "} */}
              Business with Tamkin
            </h1>
            <p className="text-white font-montserrat md:text-left xxs:text-center lg:text-[17px] lg:leading-[30px] xs:text-sm xs:leading-6 xxs:text-[11px]">
              {/* Curly Airline proudly raises the bar and exceeds the standard for
              luxury and corporate private jet charter services. We pride
              ourselves on offering a professional service. */}
              We Drive change success by preparing, equipping and supporting
              individuals to thrive throughout the transformation journey. We
              enable individuals to manage the technical and people sides of a
              change.
            </p>
            {/* <div className="py-8 md:block xxs:flex  xxs:justify-center ">
              <Button backgroundColor="white" ButtonText="Enquire Now" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
