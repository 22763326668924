import { ServiceImage1, ServiceImage2 } from "../../assets";
import { ReadMore } from "../../Components";

export const OurServices = () => {
  return (
    <div className="bg-ourservicebg">
      <div className="xxs:w-11/12   xl:w-[75%]  m-auto h-full xxs:py-8 md:py-20 ">
        <p className="text-primary lg:text-lg lg:leading-[22px] font-montserrat xxs:text-base">
          OUR SERVICES
        </p>
        <div className="pt-2">
          <h1 className="xs:text-xl xs:leading-[35px] xxs:leading-[30px] md:text-2xl md:leading-[35px] 2xl:text-[50px] 2xl:leading-[53px] xl:text-[30px] xl:leading-[45px] font-bold font-montserrat md:text-center xxs:text-center">
            We Offer Professional Solutions
            <br /> For Your Business{" "}
          </h1>
        </div>
        {/* <div className="flex flex-wrap justify-center gap-8 xxs:pt-8 md:py-16 lg:flex-row xxs:flex-col">
          {OurServicesData.map((item, index) => {
            return (
              // <div
              //   className="bg-white md:w-[45%] lg:w-[40%] xl:w-[40%]"
              //   key={index}
              // >

              <div
                className="bg-white md:w-11/2 lg:w-[48%] xl:w-[40%]"
                key={index}
              >
                <div className="w-full h-[350px] image-card">
                  <img
                    src={item.image}
                    alt={item.image}
                    className="w-full h-full object-cover card-image"
                  />
                </div>
                <div className="flex flex-col items-start gap-4 px-4 py-4">
                  <h3 className="text-lg font-bold  font-montserrat">
                    {item.title}
                  </h3>
                  <p className="w-full text-start text-base leading-[26px] font-montserrat 2xl:h-[250px] xl:h-auto lg:h-[380px] md:h-auto">
                    {item.about}
                  </p>
                  <Link
                    to={item.link}
                    className="flex flex-row items-center justify-center text-primary"
                  >
                    <p className="m-0 text-lg leading-6">View More</p>
                    <div className="flex w-5 h-auto ml-2 py-4">
                      <img src={RightArrow} alt={RightArrow} />
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div> */}

        <div
          className="grid md:grid-cols-2 xxs:grid-cols-1 gap-4 pt-8"
          id="check"
        >
          <div className="bg-white">
            <div className="w-full h-[350px] image-card">
              <img
                src={ServiceImage1}
                alt="ServiceImage1"
                className="w-full h-full object-cover card-image"
              />
            </div>
            <div className="flex flex-col items-start gap-4 px-4 py-4">
              <h3 className="text-lg font-bold  font-montserrat">
                Change Management
              </h3>
              <p className="w-full text-start text-base leading-[26px] font-montserrat ">
                <ReadMore>
                  Any changes to be successful, we must prepare, equip and
                  support individuals moving through changes so that they
                  successfully adopt the changes. Without adoption, changes will
                  not be successful and we will not deliver the desired
                  outcomes. We support people in the organization through their
                  transitions from the current state to the future state, and
                  understand what is needed to influence each person to embrace
                  and adopt the change. In this way, organizations can
                  significantly increase the chances of project success and
                  their project investments paying off.
                </ReadMore>
              </p>
              {/* <Link
                to="/"
                className="flex flex-row items-center justify-center text-primary"
              >
                <p className="m-0 text-lg leading-6">View More</p>
                <div className="flex w-5 h-auto ml-2 py-4">
                  <img src={RightArrow} alt={RightArrow} />
                </div>
              </Link> */}
            </div>
          </div>
          <div className="bg-white">
            <div className="w-full h-[350px] image-card">
              <img
                src={ServiceImage2}
                alt="ServiceImage1"
                className="w-full h-full object-cover card-image"
              />
            </div>
            <div className="flex flex-col items-start gap-4 px-4 py-4">
              <h3 className="text-lg font-bold  font-montserrat">
                Project Management
              </h3>
              <p className="w-full text-start text-base leading-[26px] font-montserrat ">
                <ReadMore>
                  project management helps to ensure that project goals are met,
                  stakeholders are satisfied, and the project is completed on
                  time and within budget. It provides a structured approach to
                  managing projects and helps to prevent problems and ensure
                  success. We support people in the organization deepen their
                  understanding of project management best practices and gain
                  new skills and knowledge. We help individuals demonstrated
                  expertise ability, enhance career prospect, improve
                  credibility and expand professional network.
                </ReadMore>
              </p>
              {/* <Link
                to="/"
                className="flex flex-row items-center justify-center text-primary"
              >
                <p className="m-0 text-lg leading-6">View More</p>
                <div className="flex w-5 h-auto ml-2 py-4">
                  <img src={RightArrow} alt={RightArrow} />
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
