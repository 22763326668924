import { AboutConsultImage } from "../../assets";
import { ReadMore } from "../../Components/ReadMore";

export const ManagementConsulting = () => {
  return (
    <>
      <div className="md:pt-20 xxs:pt-4">
        <div>
          <img src={AboutConsultImage} alt="group" />
        </div>
        <div className="xxs:w-11/12  xl:w-3/4 mx-auto  xxs:my-4">
          <h1 className="font-bold 2xl:text-[40px] 2xl:leading-[53px] lg:text-[30px] md:text-[22px] xs:text-[23px] xxs:text-[18px] font-montserrat py-8">
            Welcome in our world{" "}
          </h1>
          <p className="md:text-base md:leading-[28px] xxs:text-sm xxs:leading-[25px] font-montserrat 2xl:w-[92%] xxs:w-11/12 mx-auto xxs:hidden md:block">
            <b> Welcome to Tamkin Digtial Transformation company ,</b>
            {/* <p className="md:text-base md:leading-[28px] xxs:text-sm xxs:leading-[25px] font-montserrat py-8"> */}
            a leading consultancy firm dedicated to helping businesses achieve
            their goals through effective and efficient consulting services. Our
            team of experienced professionals has a proven track record of
            delivering high-quality solutions that meet the unique needs and
            challenges of our clients. We specialize in project management,
            change management, training and development, and other areas
            critical to business success. Our approach is collaborative and
            customized, and we work closely with our clients to understand their
            specific needs and challenges. We believe that our success is
            directly tied to the success of our clients, and we are committed to
            delivering the highest level of service and support to help our
            clients achieve their goals. Explore our website to learn more about
            our services and how we can help your business thrive.
            {/* </p> */}
          </p>
          <p className="md:text-base md:leading-[28px] xxs:text-sm xxs:leading-[25px] font-montserrat 2xl:w-[92%] xxs:w-11/12 mx-auto md:hidden">
            <ReadMore>
              <b> Welcome to Tamkin Digtial Transformation company ,</b>a
              leading consultancy firm dedicated to helping businesses achieve
              their goals through effective and efficient consulting services.
              Our team of experienced professionals has a proven track record of
              delivering high-quality solutions that meet the unique needs and
              challenges of our clients. We specialize in project management,
              change management, training and development, and other areas
              critical to business success. Our approach is collaborative and
              customized, and we work closely with our clients to understand
              their specific needs and challenges. We believe that our success
              is directly tied to the success of our clients, and we are
              committed to delivering the highest level of service and support
              to help our clients achieve their goals. Explore our website to
              learn more about our services and how we can help your business
              thrive.
            </ReadMore>

            {/* <p className="md:text-base md:leading-[28px] xxs:text-sm xxs:leading-[25px] font-montserrat py-8">
              <ReadMore>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text.
              </ReadMore>
            </p> */}
          </p>
        </div>
      </div>
    </>
  );
};
