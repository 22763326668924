import { useState } from "react";
// import { HashLink } from "react-router-hash-link";

export const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="text-primary">
        {isReadMore ? (
          "...read more"
        ) : (
          // <HashLink to="/logistic_division/#check">show less</HashLink>
          <span>show less</span>
        )}
      </span>
    </p>
  );
};
