import {
  Address,
  CheckIcons,
  ClientIcon,
  ImpactImage,
  LimitImage,
  Mail,
  Phone,
  ProjectGraphImage,
  ServiceImage1,
  ServiceImage2,
  TeamMember1,
  TeamMember2,
  TeamMember3,
  TeamMember4,
  TeamMember5,
} from "../assets";

export const ProjectData = [
  {
    count: 175,
    name: "Active Project",
  },
  {
    count: 120,
    name: "Expert Team",
  },

  {
    count: 30,
    name: "Years Experience",
  },
];

export const OurServicesData = [
  {
    image: ServiceImage1,
    // title: "Smart Metering",
    title: "Change Management ",

    about:
      // "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis.",
      "Any changes to be successful, we must prepare, equip and support individuals moving through changes so that they successfully adopt the changes. Without adoption, changes will not be successful and we will not deliver the desired outcomes. We support people in the organization through their transitions from the current state to the future state, and understand what is needed to influence each person to embrace and adopt the change. In this way, organizations can significantly increase the chances of project success and their project investments paying off.",
    link: "/",
  },
  {
    image: ServiceImage2,
    // title: "Digital Transformation",
    title: "Project Management ",

    about:
      // "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis.",
      "project management helps to ensure that project goals are met, stakeholders are satisfied, and the project is completed on time and within budget. It provides a structured approach to managing projects and helps to prevent problems and ensure success. We support people in the organization deepen their understanding of project management best practices and gain new skills and knowledge. We help individuals demonstrated expertise ability, enhance career prospect, improve credibility and expand professional network. ",
    link: "/",
  },

  // {
  //   image: ServiceImage3,
  //   title: "Process Re-engineering",
  //   about:
  //     "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis.",
  //   link: "/",
  // },
  // {
  //   image: ServiceImage4,
  //   title: "PMO formulation.",
  //   about:
  //     "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis..",
  //   link: "/",
  // },
  // {
  //   image: ServiceImage5,
  //   title: "Digital Leadership",
  //   about:
  //     "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis.",
  //   link: "/",
  // },
  // {
  //   image: ServiceImage6,
  //   title: "Digital Culture",
  //   about:
  //     "Proin gravida nibh vel velit auctor aliquet. wean sollicitudin, lorem quis bibendum nisi elit consequat ipsum, nec sagittis.",
  //   link: "/",
  // },
];

export const BusinessData = [
  {
    icon: CheckIcons,
    title: "Strategic Vision",
    // para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    para: "We focus on our customers' needs and expectations and prioritize customer satisfaction. we offer exceptional customer service, quick response times, helpful and knowledgeable experts, and easy communication. We are trustworthy and reliable as we have a track record of delivering on promises and meeting deadlines. ",
  },
  {
    icon: CheckIcons,
    title: "Interpersonal Skills",
    para: "We are flexible and adaptable, and willing to customize our training program and consultancy services to meet our client's specific needs. we can response quickly to change and stay ahead of the competition through Innovation and adaptability. ",
    // para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    icon: CheckIcons,
    title: "Flexible Delivery Model",
    para: "We offer Competitive pricing to get the best value for your money, a value delivered by experts with extensive experience and expertise in project and change management in local and global market.",
    // para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];
export const AboutOurClient = [
  {
    icon: ClientIcon,
    // image: Lady,
    detail:
      // "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
      "Tamkin consultancy support us on our Digital Transformation journey. Their skilled consultants contribute a lot to our successful journey.",
    // name: "John Abraham",
    name: "Ms.Huda Albatashi – DT Manger – Madayn ",
  },
  {
    icon: ClientIcon,
    // image: WhiteGirlImage,
    detail:
      // "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
      "We had excellent Project Management training program by Tamkin. Their trainers’ global experience with local content create a unique mixture of practical knowledge to create ability instantly.",
    // name: "John Abraham",
    name: "Mr.Mohammed AlHadabi – Manager – MTCIT ",
  },

  {
    icon: ClientIcon,
    // image: Lady,
    detail:
      // "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
      "I have different insights and perspectives on change management which was meaningful and related. I am excited to demonstrate my new skill to my supervisor. ",
    // name: "John Abraham",
    name: "Ali Alsalmi – Participant on Change Management program.",
  },
  // {
  //   icon: ClientIcon,
  //   image: Lady,
  //   detail:
  //     // "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
  //     "I have different insights and perspectives on change management which was meaningful and related. I am excited to demonstrate my new skill to my supervisor. ",
  //   // name: "John Abraham",
  //   name: "Ali Alsalmi – Participant on Change Management program.",
  // },

  // {
  //   icon: ClientIcon,
  //   image: WhiteGirlImage,
  //   detail:
  //     "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
  //   name: "John Abraham",
  // },

  // {
  //   icon: ClientIcon,
  //   image: Lady,
  //   detail:
  //     "As well as getting to fly to many different destinations as part of their job, airplane pilots get big discounts on",
  //   name: "John Abraham",
  // },
];

export const AbouLinks = [
  {
    name: "About Us",
    link: "/about",
  },
  {
    name: "Our Course",
    link: "/",
  },
  {
    name: "Packages",
    link: "/",
  },
  {
    name: "Service",
    link: "/",
  },
  {
    name: "Contacts",
    link: "/contact",
  },
];
export const HowitWorks = [
  {
    name: "Air Ambulance",
    link: "/",
  },
  {
    name: "Business jet Charter",
    link: "/",
  },
  {
    name: "Private Helicopter",
    link: "/",
  },
  {
    name: "Global jet charter",
    link: "/",
  },
  {
    name: "Cessna Citation cj2",
    link: "/",
  },
];
export const GetStarted = [
  {
    name: "Dubai Special Tour Package",
    link: "/",
  },
  {
    name: "Tokyo japan tour Package",
    link: "/",
  },
  {
    name: "Maldives Sea Beach Tour",
    link: "/",
  },
  {
    name: "Nepal Kathmandu package",
    link: "/",
  },
  {
    name: "Mood of Goods package",
    link: "/",
  },
];

export const TeamPerson = [
  {
    img: TeamMember1,
    name: "Mr. Mohammed Alyahyaei",
    profile: "SME in Digital Transformation Strategy formulation. ",
    // fbicon: <Facebook />,
    // twittericon: <Twitter />,
    // linkedinicon: <Linkedin />,
    // instagramicon: <Instagram />,
    // fblink: "https://www.facebook.com/help/192946630752330/?helpref=uf_share",
    // twitterlink: "https://twitter.com/i/flow/login",
    // linkedinlink: "https://www.linkedin.com/login",
    // instagramlink: "https://www.instagram.com/accounts/login/",
  },
  {
    img: TeamMember2,
    name: "Dr. Yaqoob Albalushi",
    profile: "SME in Digital Transformation leadership & 4IR Expert",
    // fbicon: <Facebook />,
    // twittericon: <Twitter />,
    // linkedinicon: <Linkedin />,
    // instagramicon: <Instagram />,
    // fblink: "https://www.facebook.com/help/192946630752330/?helpref=uf_share",
    // twitterlink: "https://twitter.com/i/flow/login",
    // linkedinlink: "https://www.linkedin.com/login",
    // instagramlink: "https://www.instagram.com/",
  },
  {
    img: TeamMember3,
    name: "Mr. Hilal Ali Alabri  ",
    profile: "SME in Change Management & Communication plans.",
    // fbicon: <Facebook />,
    // twittericon: <Twitter />,
    // linkedinicon: <Linkedin />,
    // instagramicon: <Instagram />,
    // fblink: "https://www.facebook.com/help/192946630752330/?helpref=uf_share",
    // twitterlink: "https://twitter.com/i/flow/login",
    // linkedinlink: "https://www.linkedin.com/login",
    // instagramlink: "https://www.instagram.com/accounts/login/",
  },
  {
    img: TeamMember4,
    name: "Dr. Sultan Alyahyaei",
    profile:
      "SME in artificial intelligence, machine learning and big data analytics.",
    // fbicon: <Facebook />,
    // twittericon: <Twitter />,
    // linkedinicon: <Linkedin />,
    // instagramicon: <Instagram />,
    // fblink: "https://www.facebook.com/help/192946630752330/?helpref=uf_share",
    // twitterlink: "https://twitter.com/i/flow/login",
    // linkedinlink: "https://www.linkedin.com/login",
    // instagramlink: "https://www.instagram.com/accounts/login/",
  },
  {
    img: TeamMember5,
    name: "Mr. Mana Alkalbani",
    profile: "SME in Project Management Office (PMO)",
    // fbicon: <Facebook />,
    // twittericon: <Twitter />,
    // linkedinicon: <Linkedin />,
    // instagramicon: <Instagram />,
    // fblink: "https://www.facebook.com/help/192946630752330/?helpref=uf_share",
    // twitterlink: "https://twitter.com/i/flow/login",
    // linkedinlink: "https://www.linkedin.com/login",
    // instagramlink: "https://www.instagram.com/accounts/login/",
  },
];

export const SocialIcons = [
  {
    icon: <Phone />,
    name: "Phone:",
    details: " (00968)92213337",
    // otherDetails: " (00968)92213337",
    link: "tel:(00968)92213337",
  },

  {
    icon: <Mail />,
    name: "Address:",
    details:
      "Office 4015, 4th Floor, KOM 4, Knowledge Oasis, Muscat, Sultanate of Oman.",
    link: "https://BeverleyRdBrooklynNewYorkUnitedStates/",
  },
  {
    icon: <Address />,
    name: "E-mail:",
    details: "m.ali@Tamkin.om",
    // otherDetails: "m.ali@Tamkin.om",
    link: "mailto: m.ali@Tamkin.om",
  },
];

export const needManagement = [
  {
    heading: "Address different impacts on people and teams",
    para: "Your projects and initiatives have a significant impact on the ways individual people do their work on a day-to-day basis. Change can impact processes, systems, tools, job roles, workflows, mindsets, behaviours and more. And each change impacts different groups within your organization differently. A new electronic medical records system will affect people in the IT department in some ways and clinical users in completely different ways, while the change may be insignificant to the finance team.",
    image: ImpactImage,
  },
  {
    heading: "Thrive in an ever-changing world",
    para: "The days of implementing one large change every 36 months are long gone. Organizations are facing faster, more complex, more interdependent and more cross-functional change than ever before. By applying change management, organizations deliver results on each change more effectively, even while managing multiple unique changes. And an organization that works to build change competencies in their people will improve over time, maturing into a change-ready organization with the capacity to handle faster, more complex, more interdependent and more cross-functional change—and achieve strategic advantages.",
    image: "",
  },
  {
    heading: "Deliver people-dependent ROI",
    para: "Organizations undertake initiatives to capitalize on an opportunity, solve a problem, or improve performance. You can achieve some of the desired outcomes simply by installing the solution. However, much of the benefit is tied to people changing the way they do their jobs. In the Prosci CMROI Model, this is the “adoption contribution” of the project—the percentage of project benefits that depend on people changing their day-to-day work. For important projects, that number is commonly 80% to 100%. Applying change management prepares, equips and supports people, so they can successfully change how they do their jobs. This enables you to capture the adoption contribution and people-dependent portion of the project's return on investment (ROI).",
    image: "",
  },
  {
    heading: "Close the gap between requirements and results",
    para: "All too often, organizational changes end up meeting requirements without delivering expected results. They deliver the necessary outputs without delivering expected outcomes. This is what happens when the organization focuses efforts on the solution itself, rather than its benefits. Closing the gap between requirements and results, between outputs and outcomes, and between solutions and benefits requires focusing on the people who must use the change every day. How will you reap benefits from a solution if people don't adopt and use it? Applying change management enables you to close the gaps by preparing, equipping and supporting people through the change, so they can bring it to life in their daily work.",
    image: "",
  },
  {
    heading: "Increase the likelihood of project success ",
    para: "The proof is in the data. Prosci’s Best practices in Change Management research consistently shows that initiatives with excellent change management are six times more likely to meet objectives than those with poor change management. In fact, by simply moving from “poor” to “fair,” change management increases the likelihood of meeting objectives three fold. The better you apply change management, the more likely you are to deliver on project objectives.",
    image: ProjectGraphImage,
  },
  {
    heading: "Mitigate mission-critical risk",
    para: "Ignoring the people side of change creates costs and risks. When you ignore adoption and usage of a solution, you focus exclusively on meeting technical requirements. This leads to what Prosci calls  “RE” costs from redesigning, reworking, revisiting, redoing, retraining, rescoping, and in some cases, retreating. Failing to plan for and address the people side of change leads to greater absenteeism and attrition, reduced productivity, lower morale, and disengaged employees. And customers feel the impacts along with the organization. Change management helps you mitigate those mission-critical risks.",
    image: "",
  },
  {
    heading: "Limit variability in change",
    para: "Change is difficult. To the extent possible, you want to remove the chance or variability associated with project changes and initiatives. Project management accomplishes this through sequencing milestones, deliverables, activities and resources over the project or initiative's lifecycle. But these actions only address the technical side of a change as you design, develop and deliver it. Unless you proactively support and guide people through the impacts from project changes, you leave success to chance. Change management mitigates or removes variability by preparing, equipping and supporting employees, so they can engage, adopt and use the change successfully.",
    image: LimitImage,
  },
  {
    heading: "The Right Way to Treat People ",
    para: "How many times have you heard, “Our employees are our most important asset”? Yet, when a change gets implemented, employees typically receive an email on Monday for training on Tuesday and go-live on Wednesday. That is not the right way to treat people—especially your organization's most valuable asset. By proactively engaging, equipping and supporting people during times of change, you demonstrate in words and deeds that you truly value them. And the entire organization benefits as a result    ",
    image: "",
  },
];

export const ChangeManagementContent = [
  {
    title: "More than just communications",
    details:
      "Managing change is often simply comprised of a communications plan, where executive leaders or project managers communicate to the organization that there's change coming (typically on short notice). But change management is much more than communicating what is changing, such as a system implementation or an acquisition.We must support people in the organization through their transitions from the current state to the future state, and understand what is needed to influence each person to embrace and adopt the change. In this way, organizations can significantly increase the chances of project success and their project investments paying off.",
  },
  {
    title: "The need for a structured, flexible methodology",
    details:
      "Organizations adopt the discipline of change management in different ways depending on their business needs. For instance, they have a must-win technology implementation that can benefit from change management, or they decide to invest in change management as an organizational capability to support aggressive business growth. In other words, change management can vary in scope and purpose, and one organization might define and implement it differently than another.So its especially important that companies leverage a structured yet flexible approach to managing change in their organizations.",
  },
  {
    title: "Change management at the project level",
    details:
      "The application of the structured process and set of tools for leading the people side of change to achieve a desired outcome on our project. ",
  },
  {
    title: "Change management at the organizational level",
    details:
      "A leadership competency for enabling change within our organization and a strategic capability designed to increase our change capability and responsiveness Whatever change management might look like, the end goal is to help individuals make a change to help the organization be more successful. ",
  },
];
