import React from "react";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  tamkinlogonew,
} from "../../assets";

export const Footer = () => {
  return (
    <div>
      <div className="w-full border-t-[10px] border-solid border-primary  ">
        <footer className="bg-footerbackgroundcolor opacity-100   w-full text-white ">
          <div className=" ">
            <div className="xl:w-[75%] xxs:w-[90%] bg-footerbgBackground bg-no-repeat bg-cover bg-center m-auto">
              <div className=" md:gap-3  xxs:gap-4 md:py-24  xxs:pd-0 xxs:py-8 ">
                <div>
                  <div className="lg:h-28 xxs:h-28">
                    <a href="/">
                      <div className="w-56 m-auto">
                        <img src={tamkinlogonew} alt="tamkinlogo" />
                      </div>
                    </a>
                  </div>
                  <ul className="text-center md:text-sm  m-auto font-montserrat md:leading-5 xl:w-[86%] lg:w-full md:w-4/5 xxs:w-full	">
                    We believe that our success is directly tied to the success
                    of our clients, and we work tirelessly to ensure that our
                    work exceeds expectations. From project planning to
                    implementation, we maintain a focus on quality throughout
                    every stage of the process. When you choose Tamkin, you can
                    trust that you're getting the best possible service and
                    results. Contact us today to learn more about how we can
                    help you achieve your goals.
                  </ul>
                  <div className="flex flex-row items-center m-auto xxs:py-4 justify-center">
                    <ul className="p-0 mb-0 flex">
                      <li>
                        <div className="my-3 mr-3 facebook ">
                          <a
                            href=" https://www.facebook.com/help/192946630752330/?helpref=uf_share"
                            aria-label="Facebook"
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer"
                          >
                            <Facebook />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="m-3 insta">
                          <a
                            href="https://www.instagram.com/accounts/login/"
                            aria-label="Instagram"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Instagram />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="m-3 tweet">
                          <a
                            href="https://twitter.com/i/flow/login"
                            aria-label="Twitter"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Twitter />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="m-3 linked">
                          <a
                            href="https://www.linkedin.com/login"
                            aria-label="Linkedin"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Linkedin />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="md:pt-0 xxs:pt-8">
                  <div className="lg:h-24 xxs:h-16">
                    <h1 className="font-semibold font-dmSerif text-xl text-left text-white leading-6">
                      About us
                    </h1>
                  </div>
                  <ul className="font-normal text-left text-base font-montserrat leading-5">
                    <ul className=" text-left text-base font-montserrat leading-5 flex flex-col gap-6">
                      {AbouLinks.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={item.link}
                              className="text-white leading-4 hover:text-primary font-montserrat text-base"
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
                <div className="md:pt-0 xxs:pt-8">
                  <div className="lg:h-24 xxs:h-16">
                    <h1 className="font-semibold	 font-dmSerif text-xl text-left text-white leading-6	">
                      How it Works
                    </h1>
                  </div>
                  <ul className=" text-left text-base font-montserrat leading-5 flex flex-col gap-6">
                    {HowitWorks.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={item.link}
                            className="text-white leading-4 hover:text-primary font-montserrat text-base"
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="md:pt-0 xxs:pt-8">
                  <div className="lg:h-24 xxs:h-16">
                    <h1 className="font-semibold	 font-dmSerif text-xl text-left text-white leading-6">
                      Get Started{" "}
                    </h1>
                  </div>
                  <ul className=" text-left text-base font-montserrat leading-5 flex flex-col gap-6">
                    {GetStarted.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={item.link}
                            className="text-white leading-4 hover:text-primary font-montserrat text-base"
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </div>
              <hr className="w-full" />
              <div className=" font-montserrat w-full flex justify-center  font-normal py-4 leading-5 md:flex md:flex-row  md:text-left  md:text-base xxs:flex xxs:flex-col xxs:text-center ">
                <div className="lg:flex xxs:flex xxs:flex-wrap xxs:justify-center">
                  <div className="lg:flex xxs:flex ">
                    <span>Copyright</span> &nbsp;
                    <span>&copy;</span>
                    &nbsp;
                  </div>
                  <a
                    href="/"
                    className="text-white font-bold hover:text-white"
                    aria-label="Tamkin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tamkin
                  </a>
                  &nbsp;
                  <p className="">All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
