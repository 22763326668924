import { NavBar, HomeBanner, Footer } from "../../Components";
import {
  AboutLanguage,
  FreeTrial,
  GrowWithUs,
  OurClient,
  OurServices,
  WeOffer,
  WhychooseUs,
} from "../../Container";

export const Home = () => {
  return (
    <>
      <NavBar />
      <HomeBanner />
      <AboutLanguage />
      <WeOffer />
      <OurServices />
      <FreeTrial />
      <WhychooseUs />
      <OurClient />
      <GrowWithUs />
      <Footer />
    </>
  );
};
