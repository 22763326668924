export const FreeTrial = () => {
  return (
    <>
      <div className="py-20">
        <div className="w-full m-auto bg-trialBackground bg-center bg-no-repeat bg-cover  ">
          <div className="bg-trialgradient h-full opacity-100 p-4 mix-blend-hard-light	">
            <div className="text-white h-full flex flex-col justify-center items-center">
              <h1 className="font-montserrat font-bold xl:w-[80%] xl:text-[40px] xl:leading-[53px] md:text-[25px] md:leading-[36px] xs:text-[19px] xs:leading-[30px] xxs:text-base xs:w-full">
                {/* We Are Offering 14 Days Free Trial */}
                We offer you customize Training & Consultancy for successful
                project implementation and change Management.
              </h1>
              <p className="w-[70%] font-sans lg:text-base lg:leading-[29px] pt-8 pb-2 xs:text-sm xs:leading-[20px] xxs:text-[11px] xxs:leading-[19px]">
                we maintain a focus on quality throughout every stage of the
                process. We use best practices and proven methodologies to
                deliver reliable and effective solutions that help our clients
                achieve their goals. Our commitment to quality is evident in
                everything we do, and we are dedicated to continuously improving
                our services to ensure that we meet and exceed the expectations
                of our clients.
              </p>
              {/* <div className="pb-3">
                <button className="border border-solid border-white p-4  md:py-[20px] md:px-[58px] md:text-xl md:leading-[27px]  xxs:px-[60px]">
                  Enquire Now
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
