import { AdkarImage, PhaseImage, ProsciImage } from "../../assets";

export const ProcessOfChangeManagement = () => {
  return (
    <>
      <div className="xxs:w-11/12 xl:w-3/4 mx-auto pb-16">
        <h1 className="font-bold 2xl:text-[40px] 2xl:leading-[53px] lg:text-[30px] md:text-[22px] xxs:text-lg font-montserrat py-8">
          Process of Change Management :
        </h1>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat  text-start text-bordercolor">
          The Prosci Methodology is one of the most widely used approaches to
          change management in the world. In fact, Prosci’s offices on numerous
          continents along with over 30 global partners deliver training and
          support related to the methodology in every corner of the globe. To
          date, Prosci has certified more than 100,000 practitioners worldwide.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start py-4 text-bordercolor">
          The Prosci Methodology and its associated models, tools and processes
          are based on 25+ years of research, making it a true best-practices
          approach to change management. Let’s take a look at two of the main
          components of the Prosci Methodology: the Prosci ADKAR Model and the
          Prosci 3-Phase Process. Both are based on this fundamental principle:
        </p>
        <h1 className="md:text-[30px] md:leading-[53px] xs:text-[18px] xs:leading-[42px]  text-start font-montserrat py-4">
          framework for individual change
        </h1>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor text-start">
          So the question is, how do we facilitate this individual change that
          leads to organizational change? Most important, we use a structured
          process—the ADKAR Model—to guide individuals through a particular
          change to ensure that they embrace and adopt the change.
        </p>
        <div className="flex justify-center py-8">
          <img src={AdkarImage} alt="altimage" />
        </div>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor text-start">
          Practitioners, change sponsors, people managers and others are all
          involved in this process. By joining forces to apply the ADKAR Model,
          we can support people in their Awareness about why we’re making the
          change, which helps foster their Desire to make the change. We provide
          them Knowledge on how to change and ensure they have the Ability they
          need to make the change. And we offer Reinforcement to make sure the
          change sticks. Thousands of organizations today leverage the ADKAR
          Model to take on their toughest change challenges.
        </p>
        <h1 className=" md:text-[30px] md:leading-[53px] xs:text-[18px] xs:leading-[42px] text-start  py-4">
          A framework for organizational change
        </h1>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor text-start">
          While the ADKAR Model enables successful change at the individual
          level, the Prosci 3-Phase Process provides a framework for achieving
          change at the organizational level. This structured yet flexible
          process guides practitioners through performing the steps and
          activities needed to achieve success on a project or initiative.
          Practitioners apply components of the Prosci Methodology, such as the
          ADKAR Model and PCT Model, within the framework of the Prosci 3-Phase
          Process.
          <br />
          Here’s the basic structure of the Prosci 3-Phase Process and the goals
          of each phase:
        </p>
        <div className="flex justify-center w-1/2 m-auto">
          <img src={ProsciImage} alt="ProsciImage" />
        </div>
        <div className="flex justify-center   ">
          <img src={PhaseImage} alt=" PhaseImage" />
        </div>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor text-start pt-8">
          Now that you have a basic understanding about what change management
          is, why it’s important, and what a proven process for applying it
          looks like, we have many other resources available to help you gain a
          deeper understanding of change management and how it can help you and
          your organization succeed at change. For additional details about
          the ADKAR Model and Prosci 3-Phase Process, contact us.{" "}
        </p>
      </div>
    </>
  );
};
