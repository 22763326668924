import { aboutbannerBackground } from "../../assets";
import { Banner, Footer } from "../../Components";
import {
  ProcessOfChangeManagement,
  WhyChangeManagement,
} from "../../Container";

export const ChangeManagement = () => {
  return (
    <>
      <Banner
        backgroundImage={aboutbannerBackground}
        title="Change Management"
        // id="#embroidery"
      />
      <WhyChangeManagement />
      <ProcessOfChangeManagement />
      <Footer />
    </>
  );
};
