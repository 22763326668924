export const Address = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61.201"
        height="61.201"
        viewBox="0 0 61.201 61.201"
      >
        <path
          id="envelope-open-text-solid"
          d="M25.747,11.475H11.475V32.681L.024,24.206A7.646,7.646,0,0,1,3.1,18.731l2.642-1.96v-5.3a5.739,5.739,0,0,1,5.738-5.738h9.156L26.6,1.327A6.709,6.709,0,0,1,30.6,0a6.778,6.778,0,0,1,4,1.315L40.57,5.738h9.156a5.739,5.739,0,0,1,5.738,5.738v5.3l2.642,1.96a7.646,7.646,0,0,1,3.072,5.475L49.726,32.681V11.475H25.747ZM0,53.551V28.939L26.01,48.208a7.743,7.743,0,0,0,4.59,1.518,7.663,7.663,0,0,0,4.59-1.518L61.2,28.939V53.551h0a7.657,7.657,0,0,1-7.65,7.65H7.65A7.657,7.657,0,0,1,0,53.551ZM21.038,19.125H40.163a1.913,1.913,0,0,1,0,3.825H21.038a1.913,1.913,0,0,1,0-3.825Zm0,7.65H40.163a1.913,1.913,0,0,1,0,3.825H21.038a1.913,1.913,0,0,1,0-3.825Z"
          fill="#00BAE8"
        />
      </svg>
    </>
  );
};
