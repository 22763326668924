import { useState, useEffect } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import { AboutUS } from "./Pages/AboutUs";
import { ChangeManagement } from "./Pages/ChangeManagement";
import { Contactus } from "./Pages/Contactus";
import { Home } from "./Pages/Home";
import { ProjectManagement } from "./Pages/ProjectManagement";
import { ScrollButton } from "./Components";

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    });
  }, []);

  return (
    <div className="App ">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/changemanagement" element={<ChangeManagement />} />
        <Route path="/projectmanagement" element={<ProjectManagement />} />
        <Route path="/contact" element={<Contactus />} />
      </Routes>
      {showScrollButton && <ScrollButton />}
    </div>
  );
}

export default App;
