import React from "react";
import { ScrollToTopArrow } from "../../assets";

const ScrollButton = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <div
      className="fixed bottom-5 right-6 bg-primary rounded-md backdrop-blur cursor-pointer"
      id="scroll_button"
      onClick={goToTop}
    >
      <div className="bg-transparent flex justify-center items-center py-[25px] px-[20px] m-auto icon_container">
        <img
          src={ScrollToTopArrow}
          alt="scroll"
          className="w-[30px] h-[25px]"
        />
      </div>
    </div>
  );
};
export default ScrollButton;
