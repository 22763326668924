import { Link } from "react-router-dom";
import {
  BlackMenu,
  
  TamkinLogo,
} from "../../assets";
import { Drawer, } from "antd";
import { useState } from "react";

export const NavBar = () => {
  const [open, setOpen] = useState(false);
  // const [isActive, setIsActive] = useState(false);
  // const [isMenu, setIsMenu] = useState(false);

  // const handleClick = () => {
  //   setIsActive(!isActive);
  // };
  // const handleOpen = () => {
  //   setIsMenu(!isMenu);
  // };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="lg:w-3/4  xxs:w-11/12 py-4 m-auto ">
        <div className="flex items-center w-full justify-between">
          <div className="md:w-56 h-auto xxs:w-[9rem] xxs:h-auto">
            <a href="/">
              <img src={TamkinLogo} alt="TamkinLogo" />
            </a>
          </div>
          <div className="flex gap-6  text-base leading-5 xl:block xxs:hidden">
            <ul className="flex gap-12 mb-0">
              <li>
                <Link
                  to="/"
                  className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to="/about"
                  className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                >
                  About US{" "}
                </Link>
              </li>

              <li>
                <Link
                  to="/changemanagement"
                  className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                  
                >
                  Change Management
                 
                </Link>


                {/* <div class="solutions-dropdown">
                  <p
                    className="flex items-center gap-[10px]"
                    {
                      location.pathname === "/solutions/solution-by-scenerio" ||
                      location.pathname === "/solutions/solution-by-function"
                        ? "nav-item-in-view"
                        : "nav-item"
                    }
                  >
                    <span className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary ">
                      Change Management
                    </span>
                    <DropdownArrow />
                  </p>

                  <div class="dropdown-content">
                    <Link
                      to="/singleagency/singleagency"
                      onClick={() => setUrl("solution-by-scenerio")}
                    >
                      Single Agency
                    </Link>
                    <hr />
                    <Link
                      to="/singleagency/singleagency"
                      onClick={() => setUrl("solution-by-function")}
                    >
                      Single Agency
                    </Link>
                  </div>
                </div> */}

                
              </li>

              <li>
                <Link
                  to="/projectmanagement"
                  className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                >
                      Project Management
                </Link>
                {/* <div class="solutions-dropdown">
                  <p
                    className="flex items-center gap-[10px]"
                    {
                      location.pathname === "/solutions/solution-by-scenerio" ||
                      location.pathname === "/solutions/solution-by-function"
                        ? "nav-item-in-view"
                        : "nav-item"
                    }
                  >
                    <span className="text-base leading-[19px] font-montserrat text-navtextcolor hover:text-primary">
                      Project Management
                    </span>
                    <DropdownArrow />
                  </p>

                  <div class="dropdown-content">
                    <Link
                      to="/singleagency/singleagency"
                      onClick={() => setUrl("solution-by-scenerio")}
                    >
                      Single Agency
                    </Link>
                    <hr />

                    <Link
                      to="/singleagency/singleagency"
                       onClick={() => setUrl("solution-by-function")}
                    >
                      Single Agency
                    </Link>
                  </div>
                </div> */}
              </li>

              <li>
                <Link
                  to="/contact"
                  className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                >
                  Contact us{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="xl:hidden">
            <button onClick={showDrawer}>
              <img
                src={BlackMenu}
                alt="menu"
                className="h-8 w-8 brightness-0 bg-invert "
              />
            </button>
            <Drawer
              title={
                <img
                  src={TamkinLogo}
                  alt="TamkinLogo"
                  className="w-32 h-auto"
                />
              }
              // headerStyle="display:none"
              className="customeDrower"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <ul className="flex flex-col xxs:gap-12 xxs:pl-4 xs:pl-0">
                <li>
                  <Link
                    to="/"
                    className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    to="/about"
                    className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                  >
                    About US
                  </Link>
                </li>

                <li className="w-full">
                  <Link
                    to="/changemanagement"
                    // className="text-black uppercase hover:text-gradientColorTwo"
                    className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"

                  >
                    Change Management
                  </Link>
                  {/* <div className="w-full">
                    <div
                      className="flex justify-between "
                      onClick={handleClick}
                    >
                      <p className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary">
                        Change Management 


                      </p>
                      <img
                        src={CarouselArrow}
                        alt="arrow"
                        className={
                          isActive ? "accordion_icon" : "accordion_icon_open "
                        }
                      />
                    </div>
                    {isActive ? (
                      <div className="flex flex-col pt-6">
                        <Link
                          to="/solutions/solution-by-scenerio"
                          // onClick={() => setSolution("solution-by-scenerio")}
                          className="font-montserrat text-sm leading-10 tracking-wider font-normal	"
                        >
                      Single Agency
                        </Link>
                        <hr></hr>
                        <Link
                          to="/singleagency/singleagency"
                          // onClick={() => setSolution("solution-by-function")}
                          className="font-montserrat text-sm leading-10 tracking-wider font-normal	"
                        >
                      Single Agency
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </li>

                <li>
                  <Link to="/projectmanagement" 
                    className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                    >
                  Project Management
                  </Link>
                  {/* <div className="w-full">
                    <div className="flex justify-between " onClick={handleOpen}>
                      <p className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary">
                        Project Management
                      </p>
                      <img
                        src={CarouselArrow}
                        alt="arrow"
                        className={
                          isMenu ? "accordion_icon" : "accordion_icon_open "
                        }
                      />
                    </div>
                    {isMenu ? (
                      <div className="flex flex-col pt-6">
                        <Link
                          to="/singleagency/singleagency"
                           onClick={() => setSolution("solution-by-scenerio")}
                          className="font-montserrat text-sm leading-10 tracking-wider font-normal	"
                        >
                          Single Agency
                        </Link>
                        <hr></hr>
                        <Link
                          to="/singleagency/singleagency"
                           onClick={() => setSolution("solution-by-function")}
                          className="font-montserrat text-sm leading-10 tracking-wider font-normal	"
                        >
                          Single Agency
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </li>

                <li>
                  <Link
                    to="/contact"
                    className="text-base leading-[19px] font-montserrat text-navtextcolor  hover:text-primary"
                  >
                    Contact us{" "}
                  </Link>
                </li>
              </ul>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};
