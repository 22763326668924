import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { TeamPerson } from "../../constant";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 2,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};
export const TeamMember = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-primary border-2	 border-solid border-primary"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-dotcolor border-2 border-solid border-white"
          }
        ></div>
      </li>
    );
  };
  return (
    <div className="bg-teambg xl:mt-20">
      <div className="xxs:w-11/12 xl:w-3/4 mx-auto md:my-4 xxs:my-16">
        <div className="xxs:pt-8 md:py-44">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={2500}
            ssr={true}
            showDots={true}
            customDot={<CustomDot />}
            removeArrowOnDeviceType={[
              "mobile",
              "tablet",
              "desktop",
              "superLargeDesktop",
              "smmobile",
            ]}
          >
            {TeamPerson.map((item, index) => {
              return (
                <>
                  <div className="md:w-[80%] xl:w-[80%]" key={index}>
                    <div className="my-16">
                      <div className="w-full xl:h-[160px] 2xl:h-[230px]">
                        <img
                          src={item.img}
                          alt=""
                          className="w-full h-full object-cover "
                        />
                      </div>

                      <div className="flex bg-trialgradient justify-end items-start ">
                        <div className="px-4 py-4 bg-white w-[95%] h-[180px] hover:bg-transparent hover:text-white card_content_container button_slide slide_right">
                          <p className="text-lg font-bold font-montserrat text-left">
                            {item.name}
                          </p>
                          <p className=" text-sm  font-montserrat text-left  ">
                            {item.profile}
                          </p>
                          {/* <div className="flex gap-2 items-center justify-start py-8">
                        <a
                          href={item.twitterlink}
                          className="bg-trialgradient rounded-full p-[7px]  h-[30px] w-[30px] flex items-center justify-center"
                        >
                          {item.twittericon}
                        </a>
                        <a
                          href={item.fblink}
                          className="bg-trialgradient p-[8px] rounded-full h-[28px] w-[26px] flex items-center justify-center"
                        >
                          {item.fbicon}
                        </a>
                        <a
                          href={item.linkedinlink}
                          className="bg-trialgradient rounded-full p-[7px]  h-[30px] w-[30px] flex items-center justify-center"
                        >
                          {item.linkedinicon}
                        </a>

                        <a
                          href={item.instagramlink}
                          className="bg-trialgradient rounded-full p-[7px]  h-[30px] w-[30px] flex items-center justify-center"
                        >
                          {item.instagramicon}
                        </a>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
