export const Facebook = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.886"
        height="31.768"
        viewBox="0 0 16.886 31.768"
      >
        <path
          id="Path_2099"
          data-name="Path 2099"
          d="M1392.014,675.8l.875-5.706h-5.474v-3.7c0-1.561.765-3.083,3.217-3.083h2.489V658.45a30.337,30.337,0,0,0-4.418-.386c-4.509,0-7.456,2.732-7.456,7.68v4.349h-5.012V675.8h5.012v13.793a19.929,19.929,0,0,0,6.169,0V675.8Z"
          transform="translate(-1376.234 -658.064)"
          fill="#fff"
        />
      </svg>
    </>
  );
};
