import { useFormik } from "formik";

export const Welovetohear = () => {
  const submitHandler = (e) => {
    e.preventDefault();
  };
  const validate = (values) => {
    console.log(values, ">>>>>>>");
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.length > 20) {
      errors.name = "Must be 15 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      submitHandler();
      formik.handleReset();
    },
  });
  return (
    <>
      <div className="xxs:w-11/12  xl:w-[65%] mx-auto md:my-4 xxs:my-16">
        <div className="content_wrapper flex w-full xl:gap-8  xxs:gap-8 lg:gap-4 lg:flex-row  xxs:flex-col">
          <div className="lg:w-1/2 p-4 xxs:w-full">
            <iframe
              title="COMPANY_LOCATION"
              width="100%"
              // height="746px"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              className="lg:h-[746px] xxs:h-[446px] iframe-map"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Huttropstra%C3%9Fe%2060,%2045138%20Essen,%20Germany+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>{" "}
          </div>{" "}
          <div className="lg:w-[50%] lg:p-4 xxs:p-8 px-0 xxs:py-0 xxs:w-full ">
            <div className="text-left md:py-12 xxs:py-4 px-0">
              <p className="text-primary text-xs leading-[16px] tracking-[1.56px] text-left font-dmSerif py-8">
                LEAVE A MESSAGE
              </p>
              <span className=" font-bold 2xl:text-[40px] 2xl:leading-[49px] md:text-[30px] xs:text-xl xs:leading-[21px] font-montserrat">
                We love to hear from you
              </span>
            </div>

            <div className="">
              <form
                onSubmit={formik.handleSubmit}
                className="2xl:w-[90%] xxs:w-full flex flex-col gap-8"
              >
                <input
                  type="text"
                  placeholder="Your Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  className={
                    "focus:border-primary outline-none border border-solid border-inputborder font-montserrat leading-5 text-base md:py-4 xxs:py-3 px-4 rounded xxs:w-full " +
                    `${
                      formik.touched.name && formik.errors.name
                        ? "career-input-error"
                        : " "
                    }`
                  }
                />
                <input
                  type="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  className={
                    "focus:border-primary outline-none border border-solid border-inputborder font-montserrat  leading-5 text-base  md:py-4 xxs:py-3  px-4 rounded focus:outline-none xxs:w-full " +
                    `${
                      formik.touched.email && formik.errors.email
                        ? "career-input-error"
                        : " "
                    }`
                  }
                />
                <input
                  type="text"
                  placeholder="Subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  name="subject"
                  className={
                    " focus:border-primary outline-none border border-solid border-inputborder font-montserrat  leading-5 text-base  md:py-4 xxs:py-3  px-4 rounded focus:outline-none xxs:w-full " +
                    `${
                      formik.touched.subject && formik.errors.subject
                        ? "career-input-error"
                        : " "
                    }`
                  }
                />
                <textarea
                  rows={4}
                  name="message"
                  placeholder="Message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  className=" focus:border-primary outline-none border border-solid border-inputborder font-montserrat resize-none leading-5 text-base  md:py-4 xxs:py-3 px-4 rounded focus:outline-none xxs:w-full"
                />
                <div className="bg-primary md:py-5 md:px-14  md:w-44 flex justify-center rounded xxs:py-3 xxs:w-36 cursor-pointer">
                  <button
                    type="submit"
                    className="font-medium text-white text-xs leading-[15px] font-montserrat "
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
