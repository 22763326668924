import { aboutbannerBackground } from "../../assets";
import { Banner, Footer, AboutWeOffer } from "../../Components";
import {
  AboutLanguage,
  ManagementConsulting,
  TeamMember,
} from "../../Container";

export const AboutUS = () => {
  return (
    <>
      <Banner
        backgroundImage={aboutbannerBackground}
        title="About Us"
        // id="#embroidery"
      />
      <AboutLanguage />
      <AboutWeOffer />
      <ManagementConsulting />
      {/* <Count /> */}
      <TeamMember />

      <Footer />
    </>
  );
};
