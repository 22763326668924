import { SocialIcons } from "../../constant";

export const SocialInfo = () => {
  return (
    <>
      <div className="xxs:w-11/12  xl:w-3/4 mx-auto md:my-32 xxs:my-16">
        <div className=" xl:gap-0  md:gap-8 m-auto md:grid md:grid-cols-3  xxs:flex xxs:flex-col">
          {SocialIcons.map((item, index) => {
            return (
              <>
                <a href={item.link} key={index} className="md:border-r-2 border-bordercolor md:border-b-0 md:p-0  xxs:border-r-0 xxs:last:border-none xxs:border-0 xxs:p-4">
                  <div className="md:border-r-2 border-bordercolor md:border-b-0 md:p-0  xxs:border-r-0 xxs:last:border-none xxs:border-b-2 xxs:p-4 ">
                    <div className=" flex w-12 h-auto justify-center items-center m-auto">
                      {item.icon}
                    </div>
                    <p className="font-semibold lg:text-[25px]  xxs:text-xl lg:leading-[30px] py-4 px-0 mb-0 text-center font-montserrat ">
                      {item.name}
                    </p>
                    <div className="flex flex-col items-center">
                      <p className="text-center font-montserrat xl:w-[83%] xl:text-[20px] xl:leading-[35px] xxs:text-base md:w-full xxs:w-4/6 m-auto">
                        {item.details}
                      </p>
                      {item?.otherDetails ? (
                        <p className="xl:text-[20px] xxs:text-base xl:leading-[35px] font-montserrat text-center">
                          {item.otherDetails}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </a>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
