export const Twitter = () => {
  return (
    <>
      <svg
        id="Group_1225"
        data-name="Group 1225"
        xmlns="http://www.w3.org/2000/svg"
        width="39.088"
        height="31.768"
        viewBox="0 0 39.088 31.768"
      >
        <g id="Group_1224" data-name="Group 1224" transform="translate(0 0)">
          <path
            id="Path_2095"
            data-name="Path 2095"
            d="M1609.912,921.242c14.751,0,22.818-12.221,22.818-22.818q0-.52-.023-1.037a16.3,16.3,0,0,0,4-4.152,16,16,0,0,1-4.605,1.262,8.044,8.044,0,0,0,3.527-4.436,16.066,16.066,0,0,1-5.092,1.947,8.027,8.027,0,0,0-13.667,7.314,22.769,22.769,0,0,1-16.53-8.379,8.026,8.026,0,0,0,2.482,10.706,7.962,7.962,0,0,1-3.632-1c0,.033,0,.067,0,.1a8.023,8.023,0,0,0,6.433,7.862,8.013,8.013,0,0,1-3.621.137,8.029,8.029,0,0,0,7.492,5.57,16.09,16.09,0,0,1-9.96,3.433,16.269,16.269,0,0,1-1.913-.111,22.7,22.7,0,0,0,12.293,3.6"
            transform="translate(-1597.619 -889.474)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};
