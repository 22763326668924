import { CommunityImage } from "../../assets";

export const GrowWithUs = () => {
  return (
    <>
      <div className="xxs:w-11/12  xl:w-3/4 mx-auto h-full">
        <div className="bg-mapBackground bg-no-repeat bg-center bg-contain  h-[500px] w-full">
          <div className="2xl:w-1/2 xxs:w-full m-auto">
            <span className="text-primary text-lg leading-[22px] font-montserrat">
              GROW WITH US
            </span>
            <h1 className="2xl:text-[40px] 2xl:leading-[56px] xs:text-[20px] font-montserrat font-bold py-4">
              What can we help you achieve?
            </h1>
            <p className="text-base leading-[29px] text-center text-[#222222] font-sans">
              Tamkin is a leading project and change management consultancy
              company that provides a range of services to support clients in
              achieving their project goals. Our experienced consultants work
              collaboratively with clients to develop customized solutions that
              meet their unique needs, whether it's project planning, monitoring
              and control, risk management, stakeholder management, change
              management, quality assurance, or training and development. We
              help clients manage projects of all sizes, from small-scale
              initiatives to complex, multi- faceted programs, and our focus is
              always on delivering measurable results. With a track record of
              success across a broad range of industries, Tamkin is the partner
              you can trust to help you achieve project success. Contact us
              today to learn more about how we can help you take your project to
              the next level. We offer you customize Training & Consultancy for
              successful project implementation and change Management. we
              maintain a focus on quality throughout every stage of the process.
              We use best practices and proven methodologies to deliver reliable
              and effective solution.
            </p>
            {/* <div className="pt-4">
              <button className="bg-primary rounded md:py-[21px] md:px-[60px] xxs:py-[18px] xxs:px-[40px] md:w-60  text-sm leading-[15px] font-montserrat text-white whitespace-nowrap">
                Let's Get To Work
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="lg:mt-[-8rem] sm:mt-[0rem] mob:mt-[18rem]  xs:mt-[23rem]  xxs:mt-[32rem]">
        <img src={CommunityImage} alt="community" className="w-full h-full" />
      </div>
    </>
  );
};
