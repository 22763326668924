import { CBCImage } from "../../assets";

export const ProjectDetails = () => {
  return (
    <>
      <div className="xxs:w-11/12 xl:w-3/4 mx-auto pb-16">
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-8">
          Project management is the use of specific knowledge, skills, tools and
          techniques to deliver something of value to people. The development of
          software for an improved business process, the construction of a
          building, the relief effort after a natural disaster, the expansion of
          sales into a new geographic market—these are all examples of projects.
        </p>
        <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[25px] xs:leading-[42px] xxs:text-[20px] text-start pt-8">
          What is a Project?
        </h3>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          To understand project management, we must look deeper into what
          constitutes a project. Essentially, projects are temporary efforts to
          create value through unique products, services, and processes. Some
          projects are engineered to quickly resolve problems. Others require
          extended timelines to produce outcomes that will not need major
          improvements outside of projected maintenance—like public highways—for
          example.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Of course, some projects will be a mixture of both these things. This
          applies to everything from developing new software to planning
          disaster relief efforts. Still, this is all very general information
          concerning what a project is. When we break them down more
          specifically, we see that projects are amalgamations of tasks,
          activities, and deliverables that must be structured and executed
          carefully to achieve a desired outcome.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Before an outcome is achieved, each aspect of a project must go
          through phases of initiation, planning, and execution. This process is
          known as the project management lifecycle, and it is the lifeblood of
          successful projects. Moreover, this cycle allows project managers to
          plan each task and activity meticulously to ensure the highest chances
          of success. Overall, a project is a well-planned endeavor that follows
          a lifecycle with a definite beginning and end.
        </p>
        <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[25px] xs:leading-[42px] xxs:text-[20px] text-start pt-4">
          Project Managers Lead Project Management
        </h3>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          All projects are a temporary effort to create value through a unique
          product, service or result. All projects have a beginning and an end.
          They have a team, a budget, a schedule and a set of expectations the
          team needs to meet. Each project is unique and differs from routine
          operations—the ongoing activities of an organization—because projects
          reach a conclusion once the goal is achieved.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          The changing nature of work due to technological advances,
          globalization and other factors means that, increasingly, work is
          organized around projects with teams being brought together based on
          the skills needed for specific tasks.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Leading these projects are Project Professionals—people who either
          intentionally or by circumstance are asked to ensure that a project
          team meets its goals. Project professionals use many different tools,
          techniques and approaches to meet the needs of a project.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Some projects are needed to quickly resolve problems, with an
          understanding that improvements will be made over a period of time.
          Other projects have a longer duration and/or produce a product or
          other outcome that will not need major improvements outside of
          projected maintenance, such as a highway.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Still others will be a mix of both of these types of projects. Project
          professionals use a variety of skills and knowledge to engage and
          motivate others to reach a project’s goals. Project professionals are
          critical to the success of projects and are highly sought after to
          help organizations achieve their goals.
        </p>
        <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[25px] xs:leading-[42px] xxs:text-[20px] text-start pt-4">
          Project Management Drives Change
        </h3>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          hroughout human history, project management has always been practiced
          informally, but it began to emerge as a distinct profession in the
          mid-20th century when a group of forward-thinking individuals from the
          aerospace, engineering, pharmaceutical, and telecommunications fields
          realized a changing world needed new tools. Motivated by the need to
          address the scheduling and resource issues associated with
          increasingly complex projects, they met to begin to set down and
          standardize the tools for a new profession. And in 1969, the Project
          Management Institute (PMI) was born.
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          Today, we live in The Project Economy, where projects are the driving
          force behind how work is done, change is realized and value is
          delivered. In The Project Economy, the worldwide growth of project
          management proves its value as a: • as a recognized and strategic
          organizational competence • as a subject for training and education •
          as a career path
        </p>
        <p className="md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-start text-bordercolor pt-4">
          It is now widely acknowledged that a basic knowledge of project
          management can provide value to people with a variety of roles in a
          vast range of endeavors. Project management skills can help a young
          student working on a science project realize success, or a corporate
          executive settle personality disputes. These skills can help a nurse
          streamline shift changes to improve patient response times on their
          ward. They can help an IT professional deliver innovative software in
          record time or help a government agency improve the services they
          provide in a more economical manner.
        </p>
        <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[25px] xs:leading-[42px] xxs:text-[20px] text-start pt-4">
          Consider Becoming Certified
        </h3>
        <p className="text-start md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor pt-4">
          Research indicates that employers will need to fill nearly 2.2 million
          new project-oriented roles each year through 2027. The Project
          Management Professional (PMP)® is the world's leading project
          management certification. The PMP® supercharges careers for project
          leaders across industries and helps organizations find the people they
          need to work smarter and perform better. But anyone involved in The
          Project Economy can improve outcomes through awareness of specific
          foundational concepts of project management, described as follows.
        </p>
        <div className="flex justify-center md:w-1/2 xxs:w-[80%] m-auto py-6">
          <img src={CBCImage} alt="CBCImage" />
        </div>

        <div className="pt-8">
          <h3 className="font-normal md:text-[30px] md:leading-[53px] xs:text-[25px] xs:leading-[42px] xxs:text-[20px] text-start pt-4">
            Who is PMI?
          </h3>
          <p className="text-start md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor pt-4">
            As the world’s leading authority on project management, PMI empowers
            people to make ideas a reality. Through global advocacy, networking,
            collaboration, research, and education, PMI prepares organizations
            and individuals to work smarter in an ever-changi ng and dynamic
            world. 
          </p>
          <p className="text-start md:text-lg xxs:text-base xxs:leading-[38px] md:leading-[32px] font-montserrat text-bordercolor pt-4">
            Building on a proud legacy dating to 1969, PMI is a “for-purpose”
            organization working in nearly every country around the world to
            advance careers, strengthen organizational success, and enable
            changemakers with new skills and ways of working to maximize their
            impact. 
          </p>
        </div>
      </div>
    </>
  );
};
