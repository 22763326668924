import { contactbannerBackground } from "../../assets";
import { Banner, SocialInfo, Footer } from "../../Components";
import { Welovetohear } from "../../Container";

export const Contactus = () => {
  return (
    <>
      <Banner backgroundImage={contactbannerBackground} title="Contact us" />
      <SocialInfo />
      <Welovetohear />

      <Footer />
    </>
  );
};
